body {
  color: white;
  background-color: #282c34;
  overscroll-behavior-y: contain;
}

button:focus {
  outline:0;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button {
  background-color: rgb(255, 167, 182);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.button.login {
  height: 100%;
  font-size: calc(18px + 1.5vmin);
  margin: 10px;
  padding: 20px 35px 20px 35px;
}

.button.logout {
  font-size: calc(6px + 1.5vmin);
  width: 30%;
  max-width: 155px;
  padding: 14px 4px;
  margin-bottom: 2px;
}

.button.add {
  font-size: calc(6px + 1.5vmin);
  width: 30%;
  max-width: 155px;
  padding: 14px 4px;
  margin-bottom: 2px;
}

.button.clear {
  font-size: calc(6px + 1.5vmin);
  width: 30%;
  max-width: 155px;
  padding: 14px 4px;
  margin-left: auto;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.nav-bar-img {
  max-width: 400px;
}

.nav-bar i {
  margin-right: 0;
}

.nav-bar a {
  margin: 20px;
  background:#282c34;
  color: rgb(255, 167, 182);
  font-weight: bold;
  cursor: pointer;
}

.nav-button.wrapper {
  margin-right: 0px;
}

.app-header-img-lg {
  margin-top: 200px;
  height: calc(300px + 4vmin);
}

.start-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.button.submit {
  padding: 10px;
  margin-top: 10px;
  max-width: 200px;
}

.app-container {
  margin-bottom: 25px;
  width: 90%;
}

.paging-container {
  display: flex;
  direction: row;
  justify-content: space-between;
  align-items: center;
  width: 10%;
  margin-right: 3px;
}

.paging-container i.enabled {
  cursor: pointer;
}

.paging-container i.disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.search-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.search-container i {
  margin: 0 8px 0 4px;
  font-size: calc(12px + 1.5vmin);
  cursor: pointer;
}

.filter-container {
  margin-right: 3px;
}

.has-results {
  color:  rgb(255, 167, 182);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  max-height: calc(80vh - 140px);
  overflow-y: auto;
  position:fixed;
  background: white;
  width: 50%;
  height: auto;
  top:30%;
  left:50%;
  transform: translate(-50%,-43%);
  padding: 20px;
  color: black;
}

.modal-main textarea {
  width: 100%;
  min-height: 100px;
  padding: 12px 15px;
  margin: 4px 0;
  box-sizing: border-box;
  border-width: 1px;
  resize: none;
  font-size: 16px;
}

.modal-main span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-main span h2 {
  color:  rgb(255, 167, 182);
}

.modal-main i {
  cursor: pointer;
  display: right;
}

.display-on-top {
  z-index: 99 !important;
}

.display-none {
  display: none;
}

input {
  width: 100%;
  padding: 12px 15px;
  margin: 4px 0;
  box-sizing: border-box;
  border-width: 1px;
  font-size: 16px;
}

.file-input input[type="file"] {
  display: none;
}

.file-input label {
  background-color: rgb(255, 167, 182);
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: 10px;
}

select {
  padding: 11px 15px;
  box-sizing: border-box;
  border-width: 1px;
}

.rating {
  font-size: calc(10px + 1.3vmin);
  margin: 0;
  padding: 0;
  position: default;
}

.workout-details {
  font-size: calc(8px + 1vmin);
  font-weight: bold;
  overflow-wrap: break-all;
  white-space: pre-line;
}

.workout-card {
  background-color: white;
  color: #282c34;
  transition: 0.3s;
  margin: 5px 0 30px 0;
  padding: 14px;
  word-wrap: break-word;
  word-break: break-word;
}

.lift-card {
  background-color: white;
  color: #282c34;
  transition: 0.3s;
  margin: 1px 0 0 0;
  padding: 14px;
  word-wrap: break-word;
  word-break: break-word;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.lift-column {
  flex: 1;
  padding: 4px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.lift-card-video {
  margin: 0;
  background-color: white;
  color: #282c34;
  align-self: center;
}

.lift-column:last-child {
  flex: 0.3;
  justify-content: right;
}

.result-container-heading {
  background-color: #282c34;
  color: white;
  padding: 10px;
  flex-grow: 1;
  border-bottom: white;
  border-bottom-style: solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.result-container-body {
  background-color: #282c34;
  color: white;
  padding: 10px;
  flex-grow: 1;
}

.workout-record-result-header-container {
  display: flex;
  align-items: center;
}

.workout-record-result-header-container i {
  margin-left: auto;
}

.workout-record-result-header-container i.hidden {
  display: none;
}

.workout-results-text {
  font-size: 14px;
  font-family: "Archivo Black", "Archivo", sans-serif;
  font-weight: normal;
  white-space: initial;
}

.pointer {
  cursor: pointer;
}

.workout-card-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.workout-heading-4 {
  font-size: calc(12px + 1vmin);
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: rgb(255, 167, 182);
  color: white;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 1rem;
}

.snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.loading-container {
  display: flex;
  flex-direction: column;
  left: 50%;  
  z-index: 1;
  align-items: center;
}

.loading-image {
  animation: bounce 1s alternate infinite;
  margin-top: 40px;
  width: 7%;
  min-width: 50px;
}

.loading-text {
  color: rgb(255, 167, 182);
}

.loading-line {
  min-width: 50px;
  background-color: rgb(255, 167, 182);
}

.liftresult-form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lift-filter-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.checkbox-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center; 
  gap: 10px; 
}

.checkbox-button-container input {
  width: auto;
}

fieldset {
  border: 0;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes bounce {
  0% { transform:  translateY(0); }
  100% { transform:  translateY(-40px); }
}

@media only screen and (max-width : 860px) { 
    .app-container {
      width: 98%;
    }

    .search-container {
      width: 100%;
    }

    .modal-main {
      width: 80%;
    }

    .app-header-img-lg {
      margin-top: 150px;
      height: calc(120px + 4vmin);
    }
    
    .nav-bar button {
      height: 30%;
      margin-right: 5px;
      font-size: calc(10px + 1.5vmin);
    }

    .nav-bar-img {
      max-width: 180px;
    }

    .nav-bar a {
      margin: 10px;
    }
}